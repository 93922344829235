import React from "react"
import { Link } from "gatsby"
import * as styles from './textImageBlock.module.scss'
import Spacer from './spacer'

export default function textImageBlock ({ headline1, headline, text, links, textimage,right }) {
    let Content = null;
    if (right === true) {
        Content = () => <div className={styles.flex}>
            <img src={textimage} alt={headline}></img>
            <div>
                { typeof headline1 !== 'undefined' && headline1.length !== 0 && <h1>{headline1}</h1> }
                { typeof headline !== 'undefined' && headline.length !== 0 && <h2>{headline}</h2> }
                <p>{text}</p>
                { typeof links !== 'undefined' && links.map((link) => <Link to={link.path}>{link.text}</Link> ) }
            </div>
        </div>
    } else {
        Content = () => <div className={styles.flex}>
            <div>
                { typeof headline1 !== 'undefined' && headline1.length !== 0 && <h1>{headline1}</h1> }
                { typeof headline !== 'undefined' && headline.length !== 0 && <h2>{headline}</h2> }
                <p>{text}</p>
                { typeof links !== 'undefined' && links.map((link) => <Link className="link link--mneme" to={link.path}>{link.text}</Link> ) }
            </div>
            <img src={textimage} alt={headline}></img>
        </div>
    }

    return <div>
        <Content />
        <Spacer size={2}/>
    </div>
};

    