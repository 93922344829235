import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import Footer from "../components/footer"
import Navi from "../components/navi"
import TextBlock from "../components/textBlock"
import HeroImage from "../components/heroImage"
import heroimage from "../images/wedding_header.jpg"
import textimage1 from "../images/rastatt/schlossFavorite.jpeg"
import TextImageBlock from "../components/textImageBlock"
import textimage2 from "../images/rastatt/battert.jpeg"
import textimage3 from "../images/rastatt/trinkhalle.jpeg"
import textimage4 from "../images/rastatt/schlossOrtenberg.jpeg"


function Weddings({ data }) {
    return (
        <main>
            <Helmet>
            <title>Black Unicorn Wedding Photography</title>
            <meta name="title" content="Black Unicorn Wedding Photography | Hochzeitsfotografie - Eure Hochzeitsfotografin in Rastatt und Umgebung" />
            <meta name="description" content="Hochzeitsfotografie in Rastatt und Umgebung. ✔individuell ✔besonders ✔qualitativ " />
            <meta name="image" content="/src/images/favicon.jpg"/>
            <meta property="og:image" content="/src/images/favicon.jpg"/>
            </Helmet>
            <Layout>
                <Navi></Navi>
                <TextBlock 
                    headline1={"Hochzeitsfotografie in Rastatt"} 
                    text={"Authentische Hochzeitsfotografie & Paarforografie in Rastatt, Baden-Baden, Karlsruhe und Umgebung, die echt und ungestellt zeigt, wie euch eure Familie und Freunde kennen. Dabei geht es mir nicht um Perfektion. Ich konzentriere mich auf echte Gefühle, den perfekten Moment und am besten erzählen meine Bilder Geschichten - hoffentlich auch bald eure."}
                    links={[{path: "/#contact", text: "Schreibt mir!"}]}
                />
                <TextBlock
                    headline={"Hochzeitslocations rund um Rastatt"}
                    text={"Orte die ich liebe"}
                    />
                <TextImageBlock
                    right={true}
                    textimage={textimage1}
                    headline={"Schloss Favorite"}
                    text={"Das Schloss Favorite ist eine der Highlights rund um Rastatt. Der Vielfältige Schlosspark, der unter anderem eine grosse offene Wiesenfläche, aber auch ein kleines Waldstück, Allee, Fluss und See umfasst, bietet Fussläufig eine schöne Varietät für Fotomotive. Ganz zu schweigen vom Schloss selbst, welches durch die schöne aussenstehenden Treppen besonders besticht."}
                    />
                <TextImageBlock
                    right={false}
                    textimage={textimage2}
                    headline={"Battertfelsen am alten Schloss Baden-Baden"}
                    text={"Die Battertfelsen am alten Schloss Baden-Baden sind zum Sonnenuntergang wirklich absolut einzigartig. Man hat einen wunderschönen Blick in das Tal und kann einige Ortschaften Baden-Badens erkennen, als auch den Merkur und die Ausläufer des Schwarzwaldes. Wer allerdings hier shooten möchte, der muss sich bewusst sein, dass man ein kurzes Stück laufen muss!"}
                    />
                <TextImageBlock
                    right={true}
                    textimage={textimage3}
                    headline={"Trinkhalle Baden-Baden"}
                    text={"Die 1839 erbaute 90 Meter lange Halle im Herzen Baden-Badens bietet durch ihre wundervoll alt-anmutende Architektur und Symmetrie einen interessanten Photo-Spot, der durch die hohen Säulen sehr beeindruckend wirkt. Im Anschluss kann man hier noch durch den Kurpark laufen oder den Michaelsberg erklimmen, vorbei an einer wundervollen Kapelle, die einen wunderschönen Flair vermitteln."}
                    />
                <TextImageBlock
                    right={false}
                    textimage={textimage4}
                    headline={"Schloss Ortenberg"}
                    text={"Ich habe sie zum ersten Mal in der Jugendherberge besucht, heute gehört Schloss Ortenberg zum festen Repertoire von Hochzeitslocations rund um Rastatt. Das wundervolle Trauzimmer im Malerturm bietet ein einzigartiges Motiv und auch die schöne Atmosphäre im Schlossgarten macht jede Hochzeit einzigartig."}
                    />
                <HeroImage link={{text: "Schreibt mir!", url:"/#contact"}} heroimage={heroimage}></HeroImage>
                <Footer></Footer>
            </Layout>
        </main>


    );
}

export default Weddings ;